import React from 'react';

import Layout from '../components/Layout';

const SchedulingPage = () => (
  <Layout fullMenu>
    <article id="main">
      <header>
        <h2>Scheduling</h2>
      </header>
      <section id="three" className="wrapper style6 special">
        <div className="inner">
          <h2>
            Click on a link below to check my availability and schedule your
            child's lesson
          </h2>
          <ul className="features three">
            <li className="icon solid fa-calendar-check three">
              <a
                href="https://calendly.com/melanie-riordan/30min"
                target="_blank"
                rel="noreferrer"
                className="cover"
              >
                <h2 style={{ transform: 'translate(0, -20px)' }}>
                  30 Min Lesson
                </h2>
              </a>
            </li>
            <li className="icon solid fa-calendar-check three">
              <a
                href="https://calendly.com/melanie-riordan/45min"
                target="_blank"
                rel="noreferrer"
                className="cover"
              >
                <h2 style={{ transform: 'translate(0, -20px)' }}>
                  45 Min Lesson
                </h2>
              </a>
            </li>
            <li className="icon solid fa-calendar-check three">
              <a
                href="https://calendly.com/melanie-riordan/60min"
                target="_blank"
                rel="noreferrer"
                className="cover"
              >
                <h2 style={{ transform: 'translate(0, -20px)' }}>
                  60 Min Lesson
                </h2>
              </a>
            </li>
          </ul>
        </div>
      </section>
    </article>
  </Layout>
);

export default SchedulingPage;
